import { Input, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

import PlacePicker from '@features/google-places/PlacePicker';
import {
  useGetCountriesQuery,
  useGetNatureOfEmploymentQuery,
  useGetEntityTypesQuery
} from '@services/canaria.services';
import { USA_STATES } from '@utils/consts';
import { extractAddressDetails } from '@utils/googlePlaces';

export const IndividualPrimaryPartyForm: React.FC<{ form: any }> = ({ form }) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: natureOfEmploymentList } = useGetNatureOfEmploymentQuery(null);
  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

  const countryOptions = countries?.map((country) => ({ label: country.englishName, value: country.id })) ?? [];

  return (
    <>
      <FormField name="fullLegalName" label="Full Legal Name" validate={['required']}>
        <Input placeholder="Enter full legal name" />
      </FormField>

      <FormField name="email" label="Email" validate={['email']}>
        <Input placeholder="Enter email" />
      </FormField>
      <Divider />

      <FormField name="gender" label="Gender">
        <StyledSelect
          options={[
            { label: 'Male', value: 'M' },
            { label: 'Female', value: 'F' }
          ]}
          placeholder="Select gender"
        />
      </FormField>

      <FormField name="dateOfBirth" label="Date of Birth" validate={['required']}>
        {({ input }) => (
          <DatePicker
            {...input}
            selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
            onChange={(date) => {
              input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
            }}
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            minDate={hundredYearsAgo}
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            customInput={<Input maxW="530px" w="100%" />}
            placeholderText="Select a date"
            dropdownMode="select"
          />
        )}
      </FormField>

      <FormField name="citizenship" label="Citizenship" validate={['required']}>
        <StyledSelect options={countryOptions} placeholder="Select citizenship" />
      </FormField>

      <FormField name="address" label="Address">
        {({ input }) => (
          <PlacePicker
            input={input}
            completeAddress={true}
            onPlaceSelected={(place) => {
              const placeDetails = extractAddressDetails(place);
              if (placeDetails.country !== '') {
                const country = countries?.find((c) => c.alpha2 === placeDetails.country);
                if (country != null) form.change('countryOfResidence', country.id);
              }
              if (placeDetails.city !== '') form.change('city', placeDetails.city);
              if (placeDetails.state !== '') form.change('state', placeDetails.state);
              if (placeDetails.postalCode !== '') form.change('postalCode', placeDetails.postalCode);
              else form.change('postalCode', '');
            }}
          />
        )}
      </FormField>

      <FormField name="countryOfResidence" label="Country of Residence" validate={['required']}>
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </FormField>

      <FormField name="state" label="State/Department">
        {({ input }) => {
          const { values } = form.getState();
          const usaId = countries?.find((c) => c.alpha3 === 'USA')?.id;
          const selectedCountry =
            typeof values.countryOfResidence === 'object' ? values.countryOfResidence.value : values.countryOfResidence;
          const isUSA = String(selectedCountry) === String(usaId);

          if (isUSA) {
            return (
              <StyledSelect
                {...input}
                options={USA_STATES.map((state) => ({ label: state, value: state }))}
                placeholder="Select state"
              />
            );
          } else {
            const actualValue = typeof input.value === 'string' ? input.value : input.value.value;
            return <Input {...input} placeholder="Enter state" value={actualValue} />;
          }
        }}
      </FormField>

      <FormField name="city" label="City">
        <Input placeholder="Enter city" />
      </FormField>

      <FormField name="postalCode" label="Postal Code" validate={['required']}>
        <Input placeholder="Enter postal code" />
      </FormField>

      <FormField name="idIssuer" label="ID Issuer/Country">
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </FormField>

      <FormField name="idType" label="ID Type" validate={['required']}>
        <StyledSelect
          options={[
            { label: 'Passport', value: 'P' },
            { label: 'National ID', value: 'N' },
            { label: 'Driver License', value: 'D' },
            { label: 'Other', value: 'O' }
          ]}
          placeholder="Select ID type"
        />
      </FormField>

      <FormField name="idNumber" label="ID Number" validate={['required']}>
        <Input placeholder="Enter ID number" />
      </FormField>

      <FormField name="placeOfBirth" label="Place of Birth">
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </FormField>

      <FormField name="natureOfEmployment" label="Nature of Employment">
        <StyledSelect
          options={natureOfEmploymentList?.map(({ value, name }) => ({ label: name, value }))}
          placeholder="Select nature of employment"
        />
      </FormField>
    </>
  );
};

export const EntityPrimaryPartyForm: React.FC = () => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: entityTypes } = useGetEntityTypesQuery(null);

  const countryOptions = countries?.map((country) => ({ label: country.englishName, value: country.id })) ?? [];

  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);
  return (
    <>
      <FormField name="fullLegalName" label="Full Legal Name" validate={['required']}>
        <Input placeholder="Enter full legal name" />
      </FormField>

      <FormField name="dbaTradeName" label="DBA/Trade Name">
        <Input placeholder="Enter DBA/Trade name" />
      </FormField>

      <Divider />

      <FormField name="entityFormationType" label="Entity Type" validate={['required']}>
        <StyledSelect
          options={entityTypes?.map(({ value, name }) => ({ label: name, value }))}
          placeholder="Select entity type"
        />
      </FormField>

      <FormField name="placeOfEstablishment" label="Place of Establishment" validate={['required']}>
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </FormField>

      <FormField name="dateOfEstablishment" label="Date of Establishment" validate={['required']}>
        {({ input }) => (
          <DatePicker
            {...input}
            onChange={(date) => {
              input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
            }}
            selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            minDate={hundredYearsAgo}
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            customInput={<Input w="100%" />}
            placeholderText="Select a date"
            dropdownMode="select"
          />
        )}
      </FormField>

      <FormField name="uniqueIdentificationNumber" label="Unique Identification Number" validate={['required']}>
        <Input placeholder="Enter unique identification number" />
      </FormField>

      <FormField name="registeredBusinessAddress" label="Registered Business Address" validate={['required']}>
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </FormField>

      <Divider />

      <FormField name="mailingAddress" label="Mailing Address">
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </FormField>

      <Divider />

      <FormField name="physicalAddress" label="Physical Business Address">
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </FormField>

      <Divider />

      <FormField name="companyWebsite" label="Company Website">
        <Input placeholder="Enter company website" />
      </FormField>
    </>
  );
};
